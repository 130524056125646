.home-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.home-header {
    text-align: center;
    margin-bottom: 20px;
}

.home-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.home-intro, .home-dnd, .home-resume {
    background: rgba(20, 20, 20, 0.75);
    padding: 15px;
    border-radius: 8px;
}

.home-footer {
    background: rgba(20, 20, 20, 0.75);
    text-align: center;
    margin-top: 40px;
    border-radius: 8px;
}

a {
    color: #007BFF;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
