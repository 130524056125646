.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link, a {
  color: #61dafb;
}

.App-link:hover, a:hover, a:active{
  color: #027899;
}

nav {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #1e1e1e; /* Adjust to match your theme */
}

nav .logo {
  margin-right: auto; /* Pushes the logo to the far left */
  height: 35px; /* Adjust height as needed */
}

nav img {
  height: 30px; /* Adjust size for the other navigation images */
  margin-left: 15px; /* Spacing between nav items */
}

nav a {
  text-decoration: none;
}

.navigation-items{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

body {
  background-image: url('../public/images/CL_Bk.jpg');
  background-color: #000000;
  background-size: contain; 
  background-repeat: no-repeat;
  background-position: center;
  color: #00c8ff; 
  margin: 0;
  padding: 0;
  height: 100vh; /* Ensure it covers the full viewport height */
}

/* Text styling */
h1, h2, h3, h4, p {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  /* More pronounced shadow */
  padding: 10px; /* Optional: adds padding around text */
  margin: 0;
  line-height: 1.4; /* Optional: improves text readability */
}

/* Example for specific elements */
h1 {
  font-size: 2rem; /* Adjust as needed */
}

h2 {
  font-size: 1.5rem; /* Adjust as needed */
}

p {
  font-size: 1rem; /* Adjust as needed */
}

.footer-text{
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.resume-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}
